import { Navigate } from "react-router-dom";

import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useShowDocTemplates, useShowEasylinks } from "common/dashboard/util";
import { useFeatureFlag } from "common/feature_gating";
import MobileBlockingModal from "common/signer/mobile_blocking_modal";
import { MOBILE_BUSINESS_PORTAL } from "constants/feature_gates";
import { OrganizationTypeEnum } from "graphql_globals";
import { useQuery } from "util/graphql/query";
import { isMobileDevice } from "util/support";

import { ToolsSidebar } from "./tools_sidebar";
import CurrentOrganizationQuery, {
  type CurrentOrganization_node_Organization as Organization,
} from "./current_organization_query.graphql";
import Styles from "./index.module.scss";

export function Tools() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(CurrentOrganizationQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = data?.node as Organization | null;
  const showTemplates = useShowDocTemplates(organization?.featureList || []);
  const showEasylinks = useShowEasylinks(organization?.featureList || []);
  const showReferrals = useFeatureFlag("transaction-referrals");
  const mobileEnabled = useFeatureFlag(MOBILE_BUSINESS_PORTAL);
  const hasToolsFeatures = showTemplates || showEasylinks || showReferrals;

  if (loading || !data?.node) {
    return <LoadingIndicator />;
  }
  if (organization?.organizationType !== OrganizationTypeEnum.BUSINESS || !hasToolsFeatures) {
    return <Navigate to="/" />;
  }
  if (isMobileDevice() && !mobileEnabled) {
    return <MobileBlockingModal />;
  }

  return (
    <div className={Styles.wrapper}>
      <ToolsSidebar organizationFeatureList={organization.featureList} />
    </div>
  );
}
