import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";

// eslint-disable-next-line import/no-restricted-paths
import Referrals from "business_portal/dashboard/referrals";
import { EasylinkManager } from "common/dashboard/easy_link";
import { useShowDocTemplates, useShowEasylinks } from "common/dashboard/util";
import { useFeatureFlag } from "common/feature_gating";
import { OrganizationTemplates } from "common/organization_templates";
import OrganizationTemplateEdit from "common/organization_templates/edit";
import OrganizationTemplatePrepare from "common/organization_templates/prepare";
import OrganizationTemplateTools from "common/organization_templates/tools";
import { SidebarV2 } from "common/sidebar";
import { EasylinkTypes, type Feature } from "graphql_globals";

import { ToolsSidebarTemplatesLink, ROUTE as TEMPLATES_ROUTE } from "./document_templates";
import { ToolsSidebarEasylinksLink, ROUTE as EASYLINKS_ROUTE } from "./easylinks";
import { ToolsSidebarReferralsLink, ROUTE as REFERRALS_ROUTE } from "./referral_campaigns";

export function ToolsSidebar(props: { organizationFeatureList: Feature[] }) {
  const { organizationFeatureList } = props;

  const showTemplates = useShowDocTemplates(organizationFeatureList);
  const showEasyLinks = useShowEasylinks(organizationFeatureList);
  const showReferrals = useFeatureFlag("transaction-referrals");

  return (
    <>
      <SidebarV2
        title={
          <FormattedMessage id="07d245dc-5197-4bf8-bd1d-748d45cbcf5d" defaultMessage="Tools" />
        }
      >
        {showTemplates && <ToolsSidebarTemplatesLink />}
        {showEasyLinks && <ToolsSidebarEasylinksLink />}
        {showReferrals && <ToolsSidebarReferralsLink />}
      </SidebarV2>
      <Routes>
        <Route index element={<Navigate to={TEMPLATES_ROUTE} replace />} />
        <Route path={TEMPLATES_ROUTE}>
          <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
          <Route path=":templateId/new" element={<OrganizationTemplateEdit />} />
          <Route path=":templateId/edit" element={<OrganizationTemplateEdit />} />
          <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
          <Route index element={<OrganizationTemplates dashboardMode />} />
        </Route>
        <Route
          path={EASYLINKS_ROUTE}
          element={
            <EasylinkManager
              allowedEasylinkTypes={[
                EasylinkTypes.IDENTIFY,
                EasylinkTypes.DOCUMENTS_PROVIDED,
                EasylinkTypes.SIGNER_UPLOAD,
              ]}
            />
          }
        />
        <Route path={REFERRALS_ROUTE} element={<Referrals />} />
      </Routes>
    </>
  );
}
